// eslint-disable-next-line max-classes-per-file
import { createRoot } from 'react-dom/client';
import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import BrowserDetector from './utils/BrowserDetect';
import './index.styl';
import InternalLoginLayout from './components/common/LoginLayout/InternalLoginLayout';

class TopBanner extends React.Component {
    render() {
        return (
            <div className="login">
                <InternalLoginLayout>
                    <div className="flex-axis">
                        <br />
                        <br />
                        <br />
                        <h1>
                            <strong>Outdated browser detected!</strong> Consider upgrading or switching to a different
                            one.
                        </h1>
                    </div>
                </InternalLoginLayout>
            </div>
        );
    }
}

class Loading extends React.Component {
    render() {
        return (
            <div className="login">
                <InternalLoginLayout>
                    <div className="login__loading">
                        <span>Loading</span>
                    </div>
                </InternalLoginLayout>
            </div>
        );
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.outdatedBrowser = !new BrowserDetector().isSupported();
    }

    render() {
        if (this.outdatedBrowser) {
            return <TopBanner />;
        }
        const Console = React.lazy(() => import('./Console'));

        return (
            <React.Suspense fallback={<Loading />}>
                <Console />
            </React.Suspense>
        );
    }
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
