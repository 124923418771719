import React from 'react';
import PropTypes from 'prop-types';
import { tr } from '@woosmap/ui';
import WhiteLogo from '../../../images/woosmap-white-logo.svg';

class InternalLoginLayout extends React.Component {
    render() {
        const { children } = this.props;
        return (
            <>
                <div className="login__logo">
                    <img src={WhiteLogo} alt="Woosmap, Location-based Search Platform" />
                    <h4 className="title">Location-based Search Platform</h4>
                </div>
                <div className="login__form">{children}</div>
                <div className="login__footer">
                    <a
                        className="btn btn--inverse btn--large"
                        target="_blank"
                        href="https://developers.woosmap.com"
                        rel="noreferrer"
                        onClick={() =>
                            window.sendGoogleAnalyticsEvent(
                                'Login',
                                'External link',
                                'User goes to the Developer Documentation',
                            )
                        }
                    >
                        {tr("Developer's Documentation")}
                    </a>
                </div>
            </>
        );
    }
}

InternalLoginLayout.propTypes = {
    children: PropTypes.any,
};

InternalLoginLayout.defaultProps = {
    children: null,
};

export default InternalLoginLayout;
